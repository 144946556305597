import IntegrySettings from '../../components/integry/integry-settings'
import { appIdConfig } from '../../util/integry-app-id'

const MyobSettings = () => {
  const appName = 'MYOB Accounting'
  const appKey = process.env.REACT_APP_INTEGRY_APP_KEY
  const appId = appIdConfig.myob

  return <IntegrySettings appName={appName} appKey={appKey} appId={appId} />
}
export default MyobSettings
