export const appIdConfig = {
  mailchimp: 502,
  constantContact: 435,
  jotform: 12405,
  keap: 2857,
  quickbooksOnline: 165,
  typeform: 422,
  googleSheets: 2,
  insightly: 48,
  hubSpot: 430,
  copper: 124,
  googleContacts: 61,
  myob: 35822
}
